<script>
import SourceNewView from '@/views/source/SourceNewView'

export default {
  name: 'SourceEditView',
  extends: SourceNewView,
  created () {
    this.getSource()
  }
}
</script>
